import React, { useState } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Button from "../Button"
import styles from './styles.module.scss'

const SignupForm = () => {

  // eslint-disable-next-line no-undef
  let api = new AmperityAPI(process.env.REACT_APP_TENANT_NAME, process.env.REACT_APP_WRITE_API_API_TOKEN, process.env.REACT_APP_WRITE_STREAM_ID)
  const pageUrl = window.location.href
  const pageTitle = `Loyalty Program Signup | ${document.title}`
  document.title = pageTitle
  const [submissionSuccess, setSubmissionSuccess] = useState(false)

  return (
    <Formik
      initialValues={{ firstName: '', lastName: '', email: '' }}
      validationSchema={Yup.object({
        firstName: Yup.string()
          .max(15, 'Must be 15 characters or less')
          .required('Required'),
        lastName: Yup.string()
          .max(20, 'Must be 20 characters or less')
          .required('Required'),
        email: Yup.string().email('Invalid email address').required('Required'),
      })}
      onSubmit={(values, { setSubmitting }) => {
        const trackedEvent = {
          'event_type': 'Account Creation',
          'page_url': pageUrl,
          'page_name': pageTitle,
          'event_datetime': new Date(),
          'email': values.email,
          'first_name': values.firstName,
          'last_name': values.lastName,
          'visitor_id': values.email
        }
        api.track(trackedEvent)
        console.log(`Calling api.track() and passing:`)
        for (const [key, value] of Object.entries(trackedEvent)) {
          console.log(`${key}: ${value}`)
        }
        setSubmitting(false)
        setSubmissionSuccess(true)
      }
      }
    >
      <Form className={styles.signup_form}>
        <div className={styles.field}>
          <label htmlFor="firstName">First Name</label>
          <span className={styles.errorMsg}><ErrorMessage name="firstName" /></span>
          <Field name="firstName" type="text" />
        </div>
        <div className={styles.field}>
          <label htmlFor="lastName">Last Name</label>
          <span className={styles.errorMsg}><ErrorMessage name="lastName" /></span>
          <Field name="lastName" type="text" />
        </div>
        <div className={styles.field}>
          <label htmlFor="email">Email Address</label>
          <span className={styles.errorMsg}><ErrorMessage name="email" /></span>
          <Field name="email" type="email" />
        </div>

        <Button type="submit">Sign up</Button>
        <p>{submissionSuccess ? "Thank you for signing up!" : null}</p>
      </Form>
    </Formik>
  );
};

export default SignupForm