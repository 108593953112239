import React from "react"

import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';

import styles from './styles.module.scss';

const Header = (props) => {
  return (
    <Navbar className={styles.navbar} bg="dark" expand="lg" variant="dark">
      <Container className={styles.navContainer}>
        <Navbar.Brand href="/">Amperity Acorn</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <NavDropdown title="Products" id="basic-nav-dropdown">
              <NavDropdown.Item href="/jeans">Jeans</NavDropdown.Item>
              <NavDropdown.Item href="/shirts">Shirts</NavDropdown.Item>
              <NavDropdown.Item href="/shoes">Shoes</NavDropdown.Item>
            </NavDropdown>
            <Nav.Link href="/loyalty">Loyalty Program</Nav.Link>
            <Nav.Link href="/custom">Custom</Nav.Link>
          </Nav>
          <Nav className={styles.account}>
            <Nav.Link onClick={props.handleUser} href="#login">
              {typeof(props.username) !== "number" ? <img src="/img/circle.png" /> : null}
              <span>{typeof(props.username) === "number" ? "Log in / Sign up" : props.username}</span>
              {typeof(props.username) !== "number" ? <span className={styles.dropdown}>&#x25BC;</span> : null}
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

export default Header;