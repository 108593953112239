import styles from './styles.module.scss'
import CustomEventsForm from '../../components/CustomEvents'

function Custom() {
  return (
    <div className={styles.custom_events}>
      <div>
        <CustomEventsForm />
      </div>

    </div>
  )
}

export default Custom;
