export default {
    jeans: [
        {
            productName: "Skinny Fit",
            price: 49,
            image: "/img/skinny_fit.jpg"
        },
        {
            productName: "Straight Fit",
            price: 39,
            image: "/img/straight_fit.jpg"
        },
        {
            productName: "Relaxed Fit",
            price: 59,
            image: "/img/relaxed_fit.jpg"
        },
        {
            productName: "Slim Fit",
            price: 44,
            image: "/img/slim_fit.jpg"
        }
    ],
    shirts: [
        {
            productName: "Flannels",
            price: 49,
            image: "/img/flannel_shirt.jpg"
        },
        {
            productName: "Tees",
            price: 59,
            image: "/img/tshirt.jpg"
        },
        {
            productName: "Dress shirts",
            price: 39,
            image: "/img/dress_shirt.jpg"
        },
        {
            productName: "Polos",
            price: 44,
            image: "/img/polo_shirt.jpg"
        }
    ],
    shoes: [
        {
            productName: "Sneakers",
            price: 49,
            image: "/img/sneakers.jpg"
        },
        {
            productName: "Heels",
            price: 39,
            image: "/img/heels.jpg"
        },
        {
            productName: "Dress shoes",
            price: 59,
            image: "/img/dress_shoes.jpg"
        },
        {
            productName: "Boots",
            price: 44,
            image: "/img/boots.jpg"
        }
    ],
}