import React from 'react'
import styles from './styles.module.scss'

const Button = ({ cta, children, type }) => {
    return (
        <button onClick={cta} className={styles.button} type={type}>
            {children}
        </button>
    )
}

export default Button