import React, { useState } from "react";
import {Outlet} from "react-router-dom";
import Header from "./layout/Header"
import Footer from "./layout/Footer"
import styles from "./global/styles.module.scss"

function App() {
  // UUID is a random 10 digit number we'll use throughout the app, except for the custom event. Math.random() has no guarantees of not clashing, so use the uuid library for anythign more serious
  const uuid = Math.floor(100000000 + Math.random() * 900000000);
  const userName = "Jesse"
  const [user, setUser] = useState(uuid);
  const handleUser = () => {
    if(user === userName) {
      setUser(uuid)
    } else {
      setUser(userName)
    }
  }

  return (
    <>
      <Header username={user} handleUser={handleUser}/>
      <main className={styles.main}>
        <div className={styles.wrapper}>
          <Outlet context={[user, setUser]} />
        </div>
      </main>
      <Footer />
    </>

  );
}

export default App;
