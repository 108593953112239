import ReactDOM from 'react-dom/client';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import './index.css';
import App from './App';
import Home from './pages/Home'
import Product from './pages/Product'
import Loyalty from './pages/Loyalty'
import Custom from './pages/Custom'

import 'bootstrap/dist/css/bootstrap.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />}>
        <Route index element={<Home />} />
        <Route path=":product" element={<Product />} />
        <Route path="loyalty" element={<Loyalty />} />
        <Route path="custom" element={<Custom />} />
      </Route>
    </Routes>
  </BrowserRouter>
);