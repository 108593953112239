import React from 'react'
import styles from './styles.module.scss'
import { Link } from "react-router-dom";

const NavCard = ({name, image, link}) => {
    return (
        <div className={styles.card}>
            <Link to={link}>
            <div className={styles.img_container}>
                <img className={styles.card_img} src={image} />
            </div>
            <h2>{name}</h2>
            </Link>
        </div>
    )
}

export default NavCard