import React from 'react'
import styles from './styles.module.scss'
import Button from '../Button'

const Card = ({ name, price, cta, image, children }) => {
  return (
    <div className={styles.card}>
      <div className={styles.img_container}>
        <img className={styles.card_img} src={image} alt={name} />
      </div>
      <h3>{name}</h3>
      <p>${price}</p>
      {children}
      <Button cta={cta}>
        Add to cart
      </Button>
    </div>
  )
}

export default Card