import React, { useState } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Button from "../Button"
import styles from './styles.module.scss'

const CustomEventsForm = () => {

  // eslint-disable-next-line no-undef
  let api = new AmperityAPI(process.env.REACT_APP_TENANT_NAME, process.env.REACT_APP_WRITE_API_API_TOKEN, process.env.REACT_APP_WRITE_STREAM_ID)
  const currentPageUrl = window.location.href
  const currentDate = new Date()
  const pageTitle = `Custom Events Form | ${document.title}`
  document.title = pageTitle
  const [submissionSuccess, setSubmissionSuccess] = useState(false)


  return (
    <Formik
      initialValues={{ eventType: '', eventDatetime: currentDate, pageUrl: currentPageUrl, pageName: pageTitle, firstPartyWebId: '' }}
      validationSchema={Yup.object({
        eventType: Yup.string()
          .required('Required'),
        eventDatetime: Yup.string()
          .required('Required'),
        pageUrl: Yup.string()
          .required('Required'),
        pageName: Yup.string()
          .required('Required'),
        firstPartyWebId: Yup.string()
          .required('Required'),
      })}
      onSubmit={(values, { setSubmitting }) => {
        const trackedEvent = {
          'event_type': values.eventType,
          'page_url': values.pageUrl,
          'page_name': values.pageName,
          'event_datetime': values.eventDatetime,
          'first_party_web_id': values.eventType,
          'first_party_cookie': values.firstPartyWebId,
          'visitor_id': values.visitorId,
          'device_id': values.deviceId,
          'mobile_ad_id': values.mobileAdId,
          'order_id': values.orderId,
          'product_id': values.productId,
          'referral_url': values.referralUrl,
          'referral_source': values.referralSource,
          'search_value': values.searchValue,
          'captured_email': values.capturedEmail,
          'campaign_id': values.campaignId,
          'adword_group': values.adwordGroup,
          'adword_keywords': values.adwordKeywords,
          'is_mobile': values.isMobile,
          'session_id': values.sessionId,
        }
        api.track(trackedEvent)
        console.log(`Calling api.track() and passing:`)
        for (const [key, value] of Object.entries(trackedEvent)) {
          console.log(`${key}: ${value}`)
        }
        setSubmitting(false)
        setSubmissionSuccess(true)
      }
      }
    >
      <Form className={styles.custom_events_form}>
        <div className={styles.mandatory_fields}>
          <h2>Mandatory Fields</h2>
          <div className={styles.field}>
            <label htmlFor="eventType">Event Type</label>
            <span className={styles.errorMsg}><ErrorMessage name="eventType" /></span>
            <Field name="eventType" as="select">
              <option value="Select one">Select one</option>
              <option value="Purchase">Purchase</option>
              <option value="Product Detail Page Browse/View">Product Detail Page Browse/View</option>
              <option value="Product Category Page Browse/View">Product Category Page Browse/VIew</option>
              <option value="Cart Abandon">Cart Abandon</option>
              <option value="Store Locator Search">Store Locator Search</option>
              <option value="High-Value Page View">High-Value Page View</option>
              <option value="Product Filter Selection">Product Filter Selection</option>
            </Field>
          </div>
          <div className={styles.field}>
            <label htmlFor="eventDatetime">event-datetime</label>
            <span className={styles.errorMsg}><ErrorMessage name="eventDatetime" /></span>
            <Field name="eventDatetime" type="text" />
          </div>
          <div className={styles.field}>
            <label htmlFor="pageUrl">page-url</label>
            <span className={styles.errorMsg}><ErrorMessage name="pageUrl" /></span>
            <Field name="pageUrl" type="text" />
          </div>
          <div className={styles.field}>
            <label htmlFor="pageName">page-name</label>
            <span className={styles.errorMsg}><ErrorMessage name="pageName" /></span>
            <Field name="pageName" type="text" />
          </div>
          <div className={styles.field}>
            <label htmlFor="firstPartyWebId">first-party-web-id</label>
            <span className={styles.errorMsg}><ErrorMessage name="firstPartyWebId" /></span>
            <Field name="firstPartyWebId" type="text" />
          </div>
        </div>
        <div className={styles.optional_fields}>
          <h2>Optional Fields</h2>
          <div className={styles.field}>
            <label htmlFor="firstPartyCookie">first-party-cookie</label>
            <span className={styles.errorMsg}><ErrorMessage name="firstPartyCookie" /></span>
            <Field name="firstPartyCookie" type="text" />
          </div>
          <div className={styles.field}>
            <label htmlFor="visitorId">visitor-id</label>
            <span className={styles.errorMsg}><ErrorMessage name="visitorId" /></span>
            <Field name="visitorId" type="text" />
          </div>
          <div className={styles.field}>
            <label htmlFor="deviceId">device-id</label>
            <span className={styles.errorMsg}><ErrorMessage name="deviceId" /></span>
            <Field name="deviceId" type="text" />
          </div>
          <div className={styles.field}>
            <label htmlFor="mobileAdId">mobile-ad-id</label>
            <span className={styles.errorMsg}><ErrorMessage name="mobileAdId" /></span>
            <Field name="mobileAdId" type="text" />
          </div>
          <div className={styles.field}>
            <label htmlFor="orderId">order-id</label>
            <span className={styles.errorMsg}><ErrorMessage name="orderId" /></span>
            <Field name="orderId" type="text" />
          </div>
          <div className={styles.field}>
            <label htmlFor="productId">product-id</label>
            <span className={styles.errorMsg}><ErrorMessage name="productId" /></span>
            <Field name="productId" type="text" />
          </div>
          <div className={styles.field}>
            <label htmlFor="referralUrl">referral-url</label>
            <span className={styles.errorMsg}><ErrorMessage name="referralUrl" /></span>
            <Field name="referralUrl" type="text" />
          </div>
          <div className={styles.field}>
            <label htmlFor="referralSource">referral-source</label>
            <span className={styles.errorMsg}><ErrorMessage name="referralSource" /></span>
            <Field name="referralSource" type="text" />
          </div>
          <div className={styles.field}>
            <label htmlFor="searchValue">search-value</label>
            <span className={styles.errorMsg}><ErrorMessage name="searchValue" /></span>
            <Field name="searchValue" type="text" />
          </div>
          <div className={styles.field}>
            <label htmlFor="captured-email">captured-email</label>
            <span className={styles.errorMsg}><ErrorMessage name="captured-email" /></span>
            <Field name="captured-email" type="text" />
          </div>
          <div className={styles.field}>
            <label htmlFor="campaignId">campaign-id</label>
            <span className={styles.errorMsg}><ErrorMessage name="campaignId" /></span>
            <Field name="campaignId" type="text" />
          </div>
          <div className={styles.field}>
            <label htmlFor="campaignName">campaign-name</label>
            <span className={styles.errorMsg}><ErrorMessage name="campaignName" /></span>
            <Field name="campaignName" type="text" />
          </div>
          <div className={styles.field}>
            <label htmlFor="adwordGroup">adword-group</label>
            <span className={styles.errorMsg}><ErrorMessage name="adwordGroup" /></span>
            <Field name="adwordGroup" type="text" />
          </div>
          <div className={styles.field}>
            <label htmlFor="adwordKeywords">adword-keywords</label>
            <span className={styles.errorMsg}><ErrorMessage name="adwordKeywords" /></span>
            <Field name="adwordKeywords" type="text" />
          </div>
          <div className={styles.field}>
            <label htmlFor="isMobile">is-mobile</label>
            <span className={styles.errorMsg}><ErrorMessage name="isMobile" /></span>
            <Field name="isMobile" type="text" />
          </div>
          <div className={styles.field}>
            <label htmlFor="sessionId">session-id</label>
            <span className={styles.errorMsg}><ErrorMessage name="sessionId" /></span>
            <Field name="sessionId" type="text" />
          </div>
        </div>
        <div className={styles.submit_button}>
          <Button type="submit">Submit</Button>
          <p>{submissionSuccess ? "The data has been submitted." : null}</p>
        </div>
      </Form>
    </Formik>
  );
};

export default CustomEventsForm