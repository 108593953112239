import styles from './styles.module.scss'
import NavCard from '../../components/NavCard'

function Home() {
  return (
    <>
      <h1>Shop by Category</h1>
      <div className={styles.card_container}>
        <NavCard name="Jeans" image="/img/relaxed_fit.jpg" link="/jeans" />
        <NavCard name="Shirts" image="/img/tshirt.jpg" link="/shirts" />
        <NavCard name="Shoes" image="/img/dress_shoes.jpg" link="/shoes" />
        <NavCard name="Loyalty Program" image="/img/loyalty.jpg" link="/loyalty" />
      </div>
    </>
  )
}

export default Home;
