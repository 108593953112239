import { useState, useEffect } from 'react'
import { useParams, useOutletContext } from 'react-router-dom'
import styles from './styles.module.scss';
import Card from '../../components/Card'
import Button from '../../components/Button'
import productFixtures from "../../fixtures/products"

function Product(props) {
  const { product } = useParams();
  const [cart, setCart] = useState([]);
  const [total, setTotal] = useState(0);
  const [numberOfItems, setNumberOfItems] = useState(0);
  const [user, setUser] = useOutletContext();
  const toTitleCase = (text) => {
    return text.replace(
      /\w\S*/g,
      function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }
    );
  }

  const products = productFixtures[product]

  // ================= AMPERITY JS TAG VARIABLES ===========================
  // eslint-disable-next-line no-undef
  let api = new AmperityAPI(process.env.REACT_APP_TENANT_NAME, process.env.REACT_APP_WRITE_API_API_TOKEN, process.env.REACT_APP_WRITE_STREAM_ID)
  const pageUrl = window.location.href
  const pageTitle = `${toTitleCase(product)} | ${document.title}`
  const firstPartyWebId = typeof (user) === "number" ? "anonymous" : `${user.toString().toLowerCase()}@someemail.com`
  // ================= END ===========================

  const handleClick = (item) => {
    // does item exist in cart?
    const idx = cart.findIndex(x => x.name === item.name);

    if (idx >= 0) {
      cart[idx].count++
      setCart([...cart])
    } else {
      item.count = 1
      setCart([...cart, item])
    }

    setTotal(total + item.price)
    setNumberOfItems(numberOfItems + 1)
  }

  const handleCheckout = () => {
    const trackedEvent = {
      'event_type': 'Purchase',
      'cart_value': total,
      'number_of_items': numberOfItems,
      'first_party_web_id': firstPartyWebId,
      'page_url': pageUrl,
      'page_name': pageTitle,
      'event_datetime': new Date(),
      'visitor_id': firstPartyWebId
    }
    api.track(trackedEvent)
    console.log(`Calling api.track() and passing:`)
    for (const [key, value] of Object.entries(trackedEvent)) {
      console.log(`${key}: ${value}`)
    }
  }

  useEffect(() => {
    document.title = pageTitle
    const trackedEvent = {
      'event_type': 'Product Browse',
      'product_id': product,
      'first_party_web_id': firstPartyWebId,
      'page_url': pageUrl,
      'page_name': pageTitle,
      'event_datetime': new Date(),
      'visitor_id': firstPartyWebId
    }
    api.track(trackedEvent)
    console.log(`Calling api.track() and passing:`)
    for (const [key, value] of Object.entries(trackedEvent)) {
      console.log(`${key}: ${value}`)
    }
  }, [pageUrl])

  return (
    <>
      <h1>{toTitleCase(product)}</h1>
      <div className={styles.product_page}>
        <div className={styles.card_container}>
          {products ? products.map((item, idx) => {
            return (
              <Card key={idx} name={item.productName} price={item.price} image={item.image} cta={() => handleClick({ name: item.productName, price: item.price })} />
            )
          }) : null}
        </div>
        <div className={styles.cart}>
          <h2>Cart</h2>
          <ul>
            {cart.map((item, i) => (
              <li key={i}>
                <h3>{item.name} {item.count > 1 ? `x ${item.count}` : ""}</h3>
                <p>{item.count > 1 ? `Price: $${item.price}.00` : ""}</p>
                <p>Subtotal: ${item.price * item.count}.00</p>
              </li>
            ))}
          </ul>
          <p className={styles.systemMsg}>{numberOfItems > 0 ? "" : "Your cart is currently empty"}</p>
          <h3>Grand total: ${total}.00</h3>
          {numberOfItems > 0 ? (
            <Button cta={handleCheckout}>
              Check out
            </Button>
          ) : ""}
        </div>
      </div>
    </>
  );
}

export default Product;
