import styles from './styles.module.scss'
import SignupForm from '../../components/LoyaltySignup'

function Loyalty() {
  return (
    <div className={styles.loyalty}>
      <div>
        <h1>Sign up for our loyalty program</h1>
        <SignupForm />
      </div>
    </div>
  )
}

export default Loyalty;
